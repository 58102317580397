import React, { useEffect, useState } from 'react';
import Header from "../sections/header/header";
import Sidebar from "./sidebar";
import BackButton from "./backButton";
import { useEvent } from '../contexts/eventsContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, realTimeDatabase } from '../firebase/firebase';
import Search from '../sections/search/search';
// import plus from "../images/plus.svg";
import Category from './category';
import { useStateValue } from '../contexts/StateProvider';
import Card from '../components/card';
import MobileNavbar from './mobileNavbar';
import { useList } from 'react-firebase-hooks/database';
import { useLoader } from '../contexts/loaderContext';
import moment from 'moment';
import { getDocWithRef, getRealtimeChild } from '../utility/helper';
import { trim } from 'jquery';

function CreateSchedule() {
    // const [modalShow, setModalShow] = React.useState(false);
    const [user] = useAuthState(auth);
    const { queryError, setQueryError, queryEvents, setQueryEvents } = useEvent();
    const [eventSnap, setEventsSnap] = useState([])
    const [categoryEventSnap, setCategoryEventsSnap] = useState([])
    const [searchLoading, setSearchLoading] = useState(false);
    const [{ userData }] = useStateValue();
    const [searchResultList, setSearchResultList] = useState([]);
    const { loader, setLoader } = useLoader();
    const [loading, setLoading] = useState(true);

    // Search functionality
    // Reference https://levelup.gitconnected.com/use-regex-and-javascript-to-improve-search-results-870932050d08
    const checkName = (name, str) => {
        var pattern = str.split("").map((x) => {
            return `(?=.*${x})`
        }).join("");
        var regex = new RegExp(`${pattern}`, "g")
        return name.match(regex);
    }


    async function handleSearch(e) {
        let searchList = [...eventSnap];
        var strSearch = e.target.value.toLowerCase();

        if (!!e.target.value && trim(e.target.value) != "") {
            setSearchLoading(true)
            const searchResults = searchList?.filter((x) => {
                if (x.ExhibitionName !== undefined) {
                    var xSub = x.ExhibitionName.substring(0, 4).toLowerCase();
                    return x.ExhibitionName.toLowerCase().includes(strSearch) || checkName(xSub, strSearch)
                }
            })

            if (searchResults.length > 0 && e.target.value !== "") {
                setQueryError('')
                // setEventsList(searchResults)
                setQueryEvents(searchResults);
                setSearchLoading(false)

            } else {
                setQueryEvents([])
                setQueryError('No matches found')
                setSearchLoading(false)
            }
        }else{
            setQueryEvents([]);
            setQueryError('');
        }
    }

    // async function handleSearch(e) {

    //     let searchList = [];
    //     var strSearch = e.target.value.toLowerCase();

    //     setSearchLoading(true)
    //     if (!e.target.value) {
    //         await realTimeDatabase.ref('Exhibitions').orderByChild('EventTimestamp').get()
    //             .then((snapshot) => {
    //                 snapshot.forEach(doc => {
    //                     const data = doc.val()
    //                     Object.assign(data, { id: doc.key })
    //                     searchList.push(data)
    //                 })
    //                 // setEventsList(searchList)
    //                 setQueryEvents(searchList)
    //                 setSearchLoading(false)
    //             })
    //     } else {
    //         if (searchResultList?.length > 0) {
    //             setQueryError('')
    //             const searchResults = searchResultList?.filter((x) => {
    //                 if (x.ExhibitionName !== undefined) {
    //                     var xSub = x.ExhibitionName.substring(0, 4).toLowerCase();
    //                     return x.ExhibitionName.toLowerCase().includes(strSearch) || checkName(xSub, strSearch)
    //                 }
    //             })

    //             if (searchResults.length > 0 && e.target.value !== "") {
    //                 setQueryError('')

    //                 // setEventsList(searchResults)
    //                 setQueryEvents(searchResults);
    //                 setSearchLoading(false)

    //             } else {
    //                 setQueryEvents([])

    //                 setQueryError('No matches found')
    //                 setSearchLoading(false)
    //             }
    //         } else {
    //             realTimeDatabase.ref('Exhibitions').orderByChild('EventTimestamp').get()
    //                 .then((snapshot) => {
    //                     snapshot.forEach(doc => {
    //                         const data = doc.val()
    //                         Object.assign(data, { id: doc.key })
    //                         searchList.push(data)
    //                     })
    //                     setSearchResultList(searchList)
    //                     setSearchLoading(false)
    //                 })
    //         }
    //     }
    //     setLoader(false)

    // }

    useEffect(() => {
        // console.log("Event Snap",eventSnap);

        onGetJoinedEventList()
        return () => {
            setQueryEvents([]);
            setQueryError('')
        }
    }, [])

    async function onGetJoinedEventList() {
        let joinedEventsList = [];
        if (user.uid) {
            //Get an array of participants that match the current user Id

            if(user?.email.includes('@clutchtime.com')){
                let clutchTimeCategory = [];
                await realTimeDatabase.ref('Exhibitions').orderByKey().get()
                .then((snapshot) => {
                    snapshot.forEach(doc => {
                        const data = doc.val()
                        Object.assign(data, { id: doc.key })
                        joinedEventsList.push(data)
                        clutchTimeCategory.push(Object.assign(doc, { id: doc.key }))
                    })
                    // setEventsList(searchList)
                    setEventsSnap(joinedEventsList)
                    setCategoryEventsSnap(clutchTimeCategory)
                    setLoading(false)
                }) 
                .catch(e => {
                    console.log(e)
                    // setLoading(false)
                })
            }
            else{
                getRealtimeChild('Participants', 'userId', user?.uid).get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {

                        // Fetch Events in each participants document using EventId field
                        
                        getDocWithRef('Exhibitions', doc.val().ExhibitionId)
                            .get()
                            .then((snapshot) => {
                                // Append the document key to the doc.val() object as an id property
                                joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                                setEventsSnap((eventSnap) =>
                                    eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                                setCategoryEventsSnap((categoryEventSnap) =>
                                    categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                                setLoading(false)
                            })
                            .catch(e => {
                                console.log(e)
                                setLoading(false)
                            })
                        // getDocWithRef('Exhibitions', doc.val().EventId)
                        //     .get()
                        //     .then((snapshot) => {
                        //         console.log("All Exhibitions Data: ",snapshot.val().ExhibitionName);
                        //         // Append the document key to the doc.val() object as an id property
                        //         joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                        //         setEventsSnap((eventSnap) =>
                        //             eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                        //         setCategoryEventsSnap((categoryEventSnap) =>
                        //             categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                        //         setLoading(false)
                        //     })
                        //     .catch(e => {
                        //         console.log(e)
                        //         setLoading(false)
                        //     })
                    })
                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })
            }
            setLoading(false)

        }
    }

    return (
        <>
            <Header />
            <div className={`user container`}>
                <div className={`d-flex`}>
                    <div className={`lg-view`}>

                        <Sidebar />
                    </div>
                    <div className='flex-column flex-grow-1'>
                        <div className={`mt-4 sm-view`}>
                            <BackButton />
                        </div>

                        <div className='container events body'>

                            <div className='lg-view'>
                                <div className='d-flex align-items-center pt-4 pl-1 pr-1'>
                                    <h4 className=' text-light'>All Events</h4>

                                    <div className="search-container flex-grow-1">
                                        <div className='search d-flex float-right'>
                                            <Search functionHandler={handleSearch} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='sm-view pl-4 pr-4'>
                                <div className='  d-flex justify-content-center align-items-center pt-4'>
                                    <div className='flex-grow-1'>
                                        {
                                            user ?
                                                <h4 className='text-light'>Hi {user?.displayName || userData?.gamerTag},</h4>
                                                :
                                                <h4 className='text-light'>All Exhibitions</h4>
                                        }
                                        <p>Let's Discover a new Adventure</p>
                                    </div>

                                    <div className="search-container flex-grow-1">
                                        <div className='search d-flex float-right'></div>
                                    </div>

                                </div>
                                <Search functionHandler={handleSearch} />

                            </div>
                            <Category event={categoryEventSnap} />

                            {queryError && queryEvents.length === 0 && <p className={`text-light text-center`}>{queryError}</p>}
                            <div className='grid-container'>
                                <>

                                    {
                                    
                                    loading || searchLoading ? <>
                                                <Card event={''} key={1} />
                                                <Card event={''} key={2} />
                                                <Card event={''} key={3} />
                                            </>
                                    
                                    :
                                    
                                    queryEvents?.length > 0 && !searchLoading ? queryEvents.map(event => {
                                        // if (moment(Date.now()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(Date.now()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                            return (
                                                <>
                                                {event !== undefined && event !== null &&
                                                <Card event={event} id={event.key} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}`:null} />}
                                                </>
                                                
                                            )
                                        // }
                                    })
                                        :
                                        eventSnap ? eventSnap.map((event, index) => {
                                            // if (moment(moment().valueOf()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(moment().valueOf()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                return (
                                                    
                                                    <>
                                                    {event !== undefined && event !== null &&
                                                    <Card event={event} id={event.id} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}/`:null} />}
                                                    </>
                                                        
                                                )
                                            // }
                                        })
                                            
                                        :
                                        <>
                                        </>

                                    }
                                </>

                            </div>

                        </div>
                        <MobileNavbar />
                    </div>
                </div>

            </div>

        </>);
}

export default CreateSchedule;