import firebase from "firebase";
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

// Initialize Firebase
// Save as environment variables
const app = firebase.initializeApp({
                apiKey: "AIzaSyDQDe9vehi8dFTsizrGHb876QwXSkTlknw",
  				authDomain: "clutchtimestaging.firebaseapp.com",
  				databaseURL: "https://clutchtimestaging-default-rtdb.firebaseio.com",
  				projectId: "clutchtimestaging",
  				storageBucket: "clutchtimestaging.appspot.com",
  				messagingSenderId: "498252099459",
  				appId: "1:498252099459:web:5d65f01209f1e460f950b6",
  				measurementId: "G-67MJKT58LR"
});

const db = app.firestore()
const realTimeDatabase = app.database();
const auth =app.auth()
const storage =app.storage()
 export {db , auth ,realTimeDatabase,storage}