import React, {useEffect, useState} from 'react';
import './userProfile.css'
import Header from "../header/header";
// import Graph from "../../components/graph";
import WinningRation from "../../components/winningRatio";
import Card from "../../components/card";
import MobileNavbar from "../../components/mobileNavbar";
import {useParams} from "react-router-dom";
import { useEvent } from '../../contexts/eventsContext';
import OtherUserMenu from "./otherUserMenu";
import {getDocWithRef, getRealtimeChild} from "../../utility/helper";
import {auth, db} from "../../firebase/firebase";
import {useLoader} from "../../contexts/loaderContext";
import Sidebar from "../../components/sidebar";
import {useStateValue} from '../../contexts/StateProvider';
import {useAuthState} from "react-firebase-hooks/auth";
import Category from '../../components/category';
import moment from 'moment';

function UserProfile() {
    const [currentUser] = useAuthState(auth)
    const [{user, otherUser}, dispatch] = useStateValue();
    const { queryError, setQueryError, queryEvents, setQueryEvents } = useEvent();
    let params = useParams();
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [eventSnap, setEventsSnap] = useState([]);
    const [categoryEventSnap, setCategoryEventsSnap] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const {setLoader, loader} = useLoader();
    let joinedEventsList = [];


    useEffect(() => {
        // console.log("Event Snap",eventSnap);

        onGetJoinedEventList()
        return () => {
            setQueryEvents([]);
            setQueryError('')
        }
    }, [])

    function onGetJoinedEventList() {
        let joinedEventsList = [];
        if (user.uid) {
            //Get an array of participants that match the current user Id
            
            getRealtimeChild('Participants', 'userId', user?.uid).get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {

                        // Fetch Events in each participants document using EventId field
                        // if(user?.email.includes('@clutchtime.com')){
                            getDocWithRef('Exhibitions', doc.val().ExhibitionId)
                                .get()
                                .then((snapshot) => {
                                    // Append the document key to the doc.val() object as an id property
                                    joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                                    setEventsSnap((eventSnap) =>
                                        eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                                    setCategoryEventsSnap((categoryEventSnap) =>
                                        categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                                    setLoading(false)
                                })
                                .catch(e => {
                                    console.log(e)
                                    setLoading(false)
                                })
                        // }
                        // getDocWithRef('Exhibitions', doc.val().EventId)
                        //     .get()
                        //     .then((snapshot) => {
                        //         console.log("All Exhibitions Data: ",snapshot.val().ExhibitionName);
                        //         // Append the document key to the doc.val() object as an id property
                        //         joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                        //         setEventsSnap((eventSnap) =>
                        //             eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                        //         setCategoryEventsSnap((categoryEventSnap) =>
                        //             categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                        //         setLoading(false)
                        //     })
                        //     .catch(e => {
                        //         console.log(e)
                        //         setLoading(false)
                        //     })
                    })
                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })

        }
    }

    // useEffect(() => {

    //     //Get other user object from firestore User collection
    //     if ( user.uid !== params.id) {
    //         setLoader(true)


    //        const unsubscribe = db.collection('User').where('objectId', "==", params.id)
    //             .onSnapshot((snapshot) => {
    //                 const otherUser = snapshot.docs.map(doc => doc.data()).find(b => {
    //                     return b
    //                 })
    //                 dispatch({
    //                     type: "SET_OTHER_USER",
    //                     otherUser,
    //                 })

    //             });
    //         setLoader(false)

    //         return () => {
    //             // Detach listener when component unmounts
    //             unsubscribe()
    //         }
    //     } else {
    //         setLoading(true)

    //         // Get an array of participants that match the current user Id
    //         var i=1;
    //         console.log(user?.uid);
    //         getRealtimeChild('Participants', 'userId', user?.uid).get()
    //             .then(async (snapshot) => {
    //                 // Get event documents
    //                 snapshot.forEach((doc) => {
                        
    //                     // getDocWithRef('Exhibitions', doc.val().EventId)
    //                     //     .get()
    //                     //     .then( async(snapshot) => {
    //                     //         joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))
                                
    //                     //         // if(snapshot.val() !== undefined && snapshot.val() !== null && snapshot.val().ExhibitionCommissionerId !== currentUser?.uid){

    //                     //             setJoinedEvents((joinedEvents) =>
    //                     //                 joinedEvents.concat(Object.assign(snapshot.val(), {id: snapshot.key}))
    //                     //             )
    //                     //             setLoading(false)
    //                     //         // }

    //                     //     })
    //                     //     .catch(e => {
    //                     //         console.log(e)
    //                     //         setLoading(false)
    //                     //     })
                            
    //                         getDocWithRef('Exhibitions', doc.val().ExhibitionId)
    //                             .get()
    //                             .then( (snapshot) => {
    //                                 joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))
                                    
    //                                 // if(snapshot.val() !== undefined && snapshot.val() !== null && snapshot.val().ExhibitionCommissionerId !== currentUser?.uid){

    //                                     setJoinedEvents((joinedEvents) =>
    //                                         joinedEvents.concat(Object.assign(snapshot.val(), {id: snapshot.key})))
    //                                     setLoading(false)
    //                                 // }s

    //                             })
    //                             .catch(e => {
    //                                 console.log(e)
    //                                 setLoading(false)
    //                             })
    //                     })
                        
    //                 })
    //                 .catch(e => {
    //                     console.log(e)
    //                     setLoading(false)
    //                 })
    //             }
    //             setLoader(false)
    // }, [])


    return (
        <>

            <Header/>
            <>

                <>
                    {user && user.uid === params.id ?
                        <>
                            <div className='container user d-flex'>
                                <Sidebar/>
                                <div className={`lg-view flex-column`}>
                                    {/* <Graph/> */}
                                    <WinningRation/>
                                    <h4 className={`text-light`}>Joined Events</h4>
                                    <Category event={categoryEventSnap} />
                                    {queryError && queryEvents.length === 0 && <p className={`text-light text-center`}>{queryError}</p>}
                                    <div className='grid-container w-100'>

                                        {/* <>

                                            {!loading && joinedEvents ? joinedEvents.reverse().map(event => {
                                                if (moment(moment().valueOf()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(moment().valueOf()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                    return (
                                                        <>
                                                            {event !== undefined && event !== null &&
                                                            <Card event={event} id={event.id} key={event.id}/>}
                                                        </>
                                                    )
                                                }
                                            }) : <></>}
                                        </> */}

                                        {
                                            queryEvents?.length > 0 && !searchLoading ? queryEvents.map(event => {
                                                // if (moment(Date.now()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(Date.now()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                    return (
                                                        <>
                                                        {event !== undefined && event !== null &&
                                                        <Card event={event} id={event.key} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}`:null} />}
                                                        </>
                                                        
                                                    )
                                                // }
                                            })
                                                :
                                            eventSnap ? eventSnap.reverse().map((event, index) => {
                                            // if (moment(moment().valueOf()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(moment().valueOf()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                return (
                                                    
                                                    <>
                                                    {event !== undefined && event !== null &&
                                                    <Card event={event} id={event.id} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}/`:null} />}
                                                    </>
                                                        
                                                )
                                            // }
                                            })
                                            :
                                            loading ? 
                                            <>
                                                <Card event={''} key={1} />
                                                <Card event={''} key={2} />
                                                <Card event={''} key={3} />
                                            </>
                                            :<></>
                                        }
                                    </div>

                                </div>

                            </div>
                        </>

                        :
                        <>
                            <div className='container other-user d-flex'>
                                <>
                                    {!loader && user.uid && otherUser?.objectId &&
                                    <OtherUserMenu otherUserObj={otherUser}/>}

                                </>

                            </div>

                        </>}
                </>
                <MobileNavbar/>

            </>
        </>
    );
}


export default UserProfile;