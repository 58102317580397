import React, { useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import '../sections/profile/userProfile.css'
import Bar from "./bar";
import { useParams } from "react-router-dom";
import { useStateValue } from '../contexts/StateProvider';
import { css } from "@emotion/react";

function WinningRation() {
    const [{ user, userData }] = useStateValue()
    let params = useParams();
    // console.log("User data++++++++++++", userData);
    const override = css`
    display: block;
    margin: 1.7% 7%;
    margin-left: 5px;
    margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");

    return (
        <div className="graph p-4">
            <div className={`d-flex text-light`}>
                <h4>Account balance</h4>{
                    params.id === user?.uid && <span className={`ml-auto`} style={{ fontSize: 17 }}>{userData ? '$ ' + (userData?.userBalance || '0') : <PuffLoader css={override} loading={true} size={15} color={color} />}</span>
                }

            </div>
            <div className={` d-flex text-light`}>
                {
                    !!userData ?
                        <div style={{ flexGrow: 2 }}><h5>Wins:  {userData && userData.userWins}</h5> </div>
                        :
                        <div style={{ flexGrow: 2, display: 'inline-flex', justifyContent: "center" }}><h5>Wins:</h5> <PuffLoader css={override} loading={true} size={15} color={color} /></div>
                }
                {
                    !!userData ?
                        <div style={{ flexGrow: 2, textAlign: "right" }}><h5>Losses:  {userData && userData.userLosses}</h5> </div>
                        :
                        <div style={{ display: 'inline-flex', justifyContent: "flex-end", textAlign: "right", flexGrow: 2 }}><h5>Losses:</h5> <span style={{ paddingTop: 5 }}><PuffLoader css={override} loading={true} size={15} color={color} /></span></div>
                }
            </div>

        </div>
    );
}

export default WinningRation;