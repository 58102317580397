import React, { useEffect, useState } from 'react';
import Header from "../header/header";
import EventSection from "../../components/eventSection";
import UserList from "../../components/userList";
import { useParams } from 'react-router-dom'
import { auth, realTimeDatabase } from "../../firebase/firebase";
import { useList, useObject } from "react-firebase-hooks/database";
import { useAuthState } from "react-firebase-hooks/auth";

const EventDetails = () => {
    let params = useParams();
    const [loading, setLoading] = useState(true);
    const [eventDetailsSnap] = useObject(realTimeDatabase.ref(`Exhibitions/${params.id}`))
    const [participantsSnap] = useList(realTimeDatabase.ref('Participants').orderByChild('ExhibitionId').equalTo(params.id))
    // const [eventParticipantsSnap] = useList(realTimeDatabase.ref('Participants').orderByChild('EventId').equalTo(params.id))
    const [hasJoined, setHasJoined] = useState(false)
    const [currentUser] = useAuthState(auth)
    const [participantsList, setParticipantsList] = useState();
    useEffect(() => {
        var participantsArr = [];
        if (participantsSnap) {
            // Check if current user ID is in the participants list
            participantsSnap.filter(user => {
                var userVal = user.val();
                // userVal.gamerTag = userVal.userGamerTag;
                userVal.gamerTag = (userVal.gamerTag) ? userVal.gamerTag : (userVal.userGamerTag) ? userVal.userGamerTag : userVal.userName;
                participantsArr.push(userVal);
                var userId = userVal.userId || userVal.objectId;
                if (userId === currentUser.uid) {
                    setHasJoined(true)
                }
            })
        }
        // if (eventParticipantsSnap) {
        //     // Check if current user ID is in the participants list
        //     eventParticipantsSnap.filter(user => {
        //         var userVal = user.val();
        //         userVal.gamerTag = (userVal.gamerTag) ? userVal.gamerTag : (userVal.userGamerTag) ? userVal.userGamerTag : userVal.userName;
        //         participantsArr.push(userVal);
        //         var userId = userVal.userId || userVal.objectId;
        //         if (userId === currentUser.uid) {
        //             setHasJoined(true)
        //         }
        //     })
        // }
        setParticipantsList(participantsArr);
        setLoading(false);

    }, [participantsSnap])





    return (
        <>
            <Header />
            {eventDetailsSnap && <div className='container event-details '>
                <EventSection isLoading={loading} event={eventDetailsSnap.val()} hasJoined={hasJoined}
                    participantsList={participantsList} />
                <div className='flex-grow-1 user-list-section'>
                    <div className=' pt-3 '>
                        <h5 className='pl-4 text-light'>Participant</h5>
                        <div className='user-list'>
                            {!loading && participantsList ? participantsList?.map(user => {
                                return (<>
                                    <UserList clickable={true} user={user} key={user.userId} />
                                </>)
                            }) : <></>}

                        </div>


                    </div>
                </div>

            </div>}
        </>

    );
};

export default EventDetails;