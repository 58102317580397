import React, {useEffect,useRef, useState} from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import './withdraw.css';
import mobile from '../../images/mobile.png';
import mail from '../../images/mail.svg';
import {useHistory} from 'react-router-dom'
import {useStateValue} from '../../contexts/StateProvider';
import {pushRealTimeData, updateFirebaseDocument, updateFirestoreDocument, updateDocument} from "../../utility/helper";
import logo from '../../images/logo.png';
import axios from 'axios';
import {css} from "@emotion/react";
import $ from 'jquery';

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";


export default function Deposit(props) {
    const [{user, userData}, dispatch] = useStateValue()
    const emailRef = useRef()
    const phoneNumberRef = useRef()
    // const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [success, setSuccess] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const override = css`
      display: block;
      margin: 0 7%;
      margin-left: auto;
      margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");

    // This values are the props in the UI
    var amount = props.amount;
    const currency = "USD";
    const style = {"layout":"vertical", "label":"pay"};

    // Custom component to wrap the PayPalButtons and handle currency changes
    const ButtonWrapper = ({ currency, showSpinner }) => {
        // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
        // This is the main reason to wrap the PayPalButtons in a new component
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

        useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    currency: currency,
                },
            });
        }, [currency, showSpinner]);


        return (<>
                { (showSpinner && isPending) && <div className="spinner" /> }
                <PayPalButtons
                    style={style}
                    disabled={false}
                    forceReRender={[amount, currency, style]}
                    fundingSource={undefined}
                    createOrder={(data, actions) => {
                        return actions.order
                            .create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: currency,
                                            value: amount,
                                        },
                                    },
                                ],
                            })
                            .then((orderId) => {
                                // Your code here after create the order
                                return orderId;
                            });
                    }}
                    onApprove={function (data, actions) {
                        return actions.order.capture().then(async function () {
                            // Your code here after capture the order
                            await setLoading(true);
                            await paymentComplete();
                        });
                    }}
                    onCancel={function (data) {
                      onHide();
                      onError('Payment Cancelled. Please try again after sometime.');
                    }}
                    onError={function (data) {
                      onHide();
                      onError('There is some error in payment. Please try again after sometime.');
                    }}
                />
            </>
        );
    }

    const onHide = async()=>{
      await props.onHide();
    }
    const onSuccess = async(message)=>{
      await props.onSuccess(message);
    }
    const onError = async(message)=>{
      await props.onError(message);
    }

    const paymentComplete = async ()=>{
      var amount = parseInt(props.amount);
      // let payeeArr = []

      // await payeeArr.push({ "amount": { "currency_code": "USD", "value": amount }, "payee": { "email_address": userData.email } })

      try{
        // const headers = {
        //   'Content-Type': 'application/json',
        //   'Access-Control-Allow-Origin': '*',
        //   'Accept': '*/*'
        // };
        
        // var data = new FormData();
        // await data.append('device', 'Web');
        // await data.append('purchase_units', JSON.stringify(payeeArr));
        // await data.append('login_user_id', userData?.objectId);
        
        // var config = {
        //   method: 'post',
        //   url: 'https://swicb.com/betaWalletTransferDeposit/public/index.php/api/create-payout',
        //   headers: headers,
        //   data : data
        // };
        
        // axios(config).then(function (response) {
        //   var resultData = response.data;
        //   if (resultData.status_code == 200) {
        //     var responseData = resultData.data;
        //     var batchHeader = responseData.batch_header;
            
        //     if (batchHeader.batch_status == "SUCCESS" || batchHeader.batch_status == "PROCESSING") {
        //       amount = parseFloat(batchHeader.amount.value);
              var userBalance = parseFloat(userData.userBalance) + amount;

                updateFirestoreDocument('User', user.uid, {userBalance: userBalance}).then(() => {
  
                    //Update userData balance in firebase userData document
                    updateFirebaseDocument('Users', user.uid, {userBalance: userBalance})
                    .catch(e => {
                        console.log(e);
                        updateFirestoreDocument('User', user.uid, {userBalance: parseFloat(userData.userBalance)})
                    })
                    
                    userData.userBalance = userBalance;
                    dispatch({
                        type:"SET_USER_DATA",
                        userData
                    })
                    setLoading(false);
                    onHide();
                    onSuccess('Deposit successful')
  
                }).catch(e => {
                        console.log(e)
                        setLoading(false);
                        onHide();
                        onError('Deposit unsuccessful')
                    }
                )
            // }
            // else{
            //   setLoading(false);
            //   onHide();
            //   onError('There is a problem to deposit.')
            //   console.log(responseData);
            // }
            // $('#checkout-message').html('<h1>Success</h1><p>Your Drop-in UI is working! Check your <a href="https://sandbox.braintreegateway.com/login">sandbox Control Panel</a> for your test transactions.</p><p>Refresh to try another transaction.</p>');
          
          // } else {
          //   setLoading(false);
          //   onHide();
          //   onError('There is a problem to deposit.')
          //   console.log(response);
          //   // $('#checkout-message').html('<h1>Error</h1><p>Check your console.</p>');
          // }

        // }).catch(function (error) {
        //   setLoading(false);
        //   console.log(error);
        // });

      } catch(ajaxcatchErr){
        setLoading(false);
        console.log("Ajax Catch ", ajaxcatchErr);
      }
          
    }



    // useEffect(() => {
    //   // setLoading(true);
    //     const script = document.createElement("script");
    //     script.src = "https://js.braintreegateway.com/web/dropin/1.33.0/js/dropin.min.js";
    //     // script.async = true;
    //     script.onload = () => braintree();
    //     document.body.appendChild(script);
    //     // setLoading(false);
    // }, [])

    // const braintree = ()=>{
    //   // setTimeout(() => {
    //     // },200)
    //     setLoading(false);

    //     var button = document.querySelector('#submit-button');

    //     window.braintree.dropin.create({
    //       // Insert your tokenization key here
    //       authorization: 'sandbox_fs5m7cxd_2fyn5wfc25kf2kv5',
    //       container: '#dropin-container',
    //         paypal: {
    //         flow: 'vault',
    //         amount: props.amount,
    //         currency: 'USD'
    //       },
    //     }, function (createErr, instance) {
    //       button.addEventListener('click', function (e) {
    //         setLoading(true);
    //         e.preventDefault();
    //         instance.requestPaymentMethod(async function (requestPaymentMethodErr, payload) {
    //           // When the user clicks on the 'Submit payment' button this code will send the
    //           // encrypted payment information in a variable called a payment method nonce
    //           var amount = parseInt(props.amount);

    //           let payeeArr = []

    //           await payeeArr.push({ "amount": { "currency_code": "USD", "value": amount }, "payee": { "email_address": userData.email } })

    //           try{
    //             const headers = {
    //               'Content-Type': 'application/json',
    //               'Access-Control-Allow-Origin': '*',
    //               'Accept': '*/*'
    //             };
                
    //             var data = new FormData();
    //             await data.append('device', 'Web');
    //             await data.append('purchase_units', JSON.stringify(payeeArr));
    //             await data.append('login_user_id', userData?.objectId);
                
    //             var config = {
    //               method: 'post',
    //               url: 'http://www.swicb.com/wallettransfer/lara/public/index.php/api/create-payout',
    //               headers: headers,
    //               data : data
    //             };
                
    //             axios(config).then(function (response) {
                  
    //               var resultData = response.data;
    //               if (resultData.status_code == 200) {
    //                 var responseData = resultData.data;
    //                 var batchHeader = responseData.batch_header;
                    
    //                 if (batchHeader.batch_status == "SUCCESS") {
    //                   var amount = parseFloat(batchHeader.amount.value);
    //                   var userBalance = parseFloat(userData.userBalance) + amount;
  
    //                     updateFirestoreDocument('User', user.uid, {userBalance: userBalance}).then(() => {
          
    //                         //Update userData balance in firebase userData document
    //                         updateFirebaseDocument('Users', user.uid, {userBalance: userBalance})
    //                         .catch(e => {
    //                             console.log(e);
    //                             updateFirestoreDocument('User', user.uid, {userBalance: parseFloat(userData.userBalance)})
    //                         })
                            
    //                         userData.userBalance = userBalance;
    //                         dispatch({
    //                             type:"SET_USER_DATA",
    //                             userData
    //                         })
    //                         setLoading(false)
    //                         setSuccess('Deposit successful')
    //                         props.onHide()
          
    //                     }).catch(e => {
    //                             console.log(e)
    //                             setError('Deposit unsuccessful')
    //                         }
    //                     )
    //                 }
    //                 else{
    //                   setError('Deposit unsuccessful')
    //                 }
    //                 // $('#checkout-message').html('<h1>Success</h1><p>Your Drop-in UI is working! Check your <a href="https://sandbox.braintreegateway.com/login">sandbox Control Panel</a> for your test transactions.</p><p>Refresh to try another transaction.</p>');
                  
    //               } else {
    //                 setError('Deposit unsuccessful')
    //                 console.log(response);
    //                 // $('#checkout-message').html('<h1>Error</h1><p>Check your console.</p>');
    //               }

    //             }).catch(function (error) {
    //               console.log(error);
    //             });

    //           } catch(ajaxcatchErr){
    //             console.log("Ajax Catch ", ajaxcatchErr);
    //           }
    //         });
    //       });
    //     });
        
    //     // window.braintree.dropin.create({
    //     //     authorization: 'sandbox_fs5m7cxd_2fyn5wfc25kf2kv5',
    //     //     container: '#dropin-container',
    //     //     applePay: {
    //     //       displayName: 'Merchant Name',
    //     //       paymentRequest: {
    //     //       label: 'Localized Name',
    //     //         total: props.amount
    //     //       }
    //     //     },
    //     //     paypal: {
    //     //       flow: 'checkout',
    //     //       amount: props.amount,
    //     //       currency: 'USD'
    //     //     },
    //     //    paypalCredit: {
    //     //      flow: 'checkout',
    //     //      amount: props.amount,
    //     //      currency: 'USD'
    //     //     },
    //     //     venmo: true
    //     //   }, function (err, dropinInstance) {
    //     //     console.log("dropinInstance =>", dropinInstance)
    //     //     console.log("err =>", err)
    //     //     dropinInstance.requestPaymentMethod(function (err, payload) {
    //     //       // Submit payload.nonce to your server
    //     //       console.log("Payload =>", payload)
    //     //       console.log("err =>", err)
    //     //     });
    //     //     // button.addEventListener('click', function () {
    //     //     // })
    //     //     // Set up a handler to request a payment method and
    //     //     // submit the payment method nonce to your server
    //     //   });
    //       // setLoading(false);
    // }

    return (
        <>
        <div>
            <form id="payment-form">
              <div className={`header-content `} style={{marginBottom:"40px"}}>
                  <img className="form-image" src={logo} alt="" style={{height:"100px",width:"100px"}}/>
                  <p className="form-title pt-4" style={{color:"#000000"}}>{`Amount : ${props.amount} USD`}</p>
              </div>
                {/* <script src="https://js.braintreegateway.com/web/dropin/1.33.0/js/dropin.min.js"
                    data-braintree-dropin-authorization="sandbox_fs5m7cxd_2fyn5wfc25kf2kv5"
                    data-locale="en_EN"
                    data-payment-option-priority='["googlePay","paypal","card", "paypalCredit"]'
                    ></script> */}
              {(loading)?
                <PuffLoader css={override}  loading={loading} size={24} color={color} />
              :
              
              
                <PayPalScriptProvider options={{
                                    "client-id": "AQc-uTTCdLO_Y2ZjJw3c--wRn9ogwoIEXFAQmt5ND_DhKiAU7mRdnM9n450cqb3foNrS4V35Mlr0I5tG",
                                    components: "buttons",
                                    currency: "USD"
                                }}>
                        <ButtonWrapper currency={currency} showSpinner={true}/>
                </PayPalScriptProvider>
              }


                {/* <div id="dropin-container">{(loading)&&<PuffLoader css={override}  loading={loading} size={24} color={color} />}</div> */}
                {/* <button id="submit-button">Submit payment</button> */}
                {/* <input type="hidden" id="nonce" name="payment_method_nonce"/>
                <input type="hidden" id="amount" name="amount"/> */}
            </form>
      </div>
</>
    );
}
