import React, {useEffect, useState} from 'react';
import Card from "./card";
import Sidebar from "./sidebar";
import {getDocWithRef, getRealtimeChild} from "../utility/helper";
import {useLoader} from "../contexts/loaderContext";
import Header from "../sections/header/header";
import BackButton from "./backButton";
import {useStateValue} from '../contexts/StateProvider';

function JoinedEvents() {
    const [{user,userData}] = useStateValue()
    const [loading,setLoading] = useState(false)
    const {setLoader} = useLoader();
    const [joinedEvents, setJoinedEvents] = useState([]);

    let joinedEventsList = [];
    useEffect( ()=>{

        setLoader(true)
        console.log(userData);

        if(user.uid){

            //Get an array of participants that match the current user Id
            getRealtimeChild('Participants', 'userId', user?.uid).get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {

                        // Fetch Events in each participants document using EventId field
                        getDocWithRef('Exhibitions', doc.val().ExhibitionId)
                            .get()
                            .then( (snapshot) => {
                                // Append the document key to the doc.val() object as an id property

                                joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))

                                setJoinedEvents((joinedEvents) =>
                                    joinedEvents.concat(Object.assign(snapshot.val(), {id: snapshot.key})))
                                setLoading(false)

                            })


                            .catch(e => {
                                console.log(e)
                                setLoading(false)
                            })
                    })



                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })

        }

        setLoader(false)
        
    },[])

    return (
        <>
            <Header/>
            <div className={` user container`}>
                <div className={`d-flex`}>
                    <div className={`lg-view`}>

                        <Sidebar/>
                    </div>
                    <div className='flex-column flex-grow-1'>
                        <div className={`mt-4 sm-view`}>
                            <BackButton/>
                        </div>

                        <div className='grid-container'>
                            {!loading && joinedEvents ? joinedEvents?.reverse().map(event => {
                              
                                return (
                                    <>
                                        {event !== undefined && event !== null && event.ExhibitionCommissionerId !== user?.uid &&
                                        <Card event={event} id={event.id} key={event.id}/>}
                                    </>
                                )
                            }) :<>

                            </> }
                        </div>


                    </div>
                </div>

            </div>

        </>
    );
}

export default JoinedEvents;