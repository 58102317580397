import './css/Home.css';
import Hero from './sections/hero/hero';
import Features from './sections/features/features';
import Performance from './sections/performance/performance';
import DownloadLinks from './sections/downloadLinks/downloadLinks';
import Testimonials from './sections/testimonials/testimonials';
import ContactUs from './sections/contactUs/contactUs';
import Footer from './sections/footer/footer';
import { useLocation,useParams,useHistory } from 'react-router-dom';
import React, {useEffect,useState} from 'react';
import {auth} from "../src/firebase/firebase";
import {useStateValue} from '../src/contexts/StateProvider';

import './css/style.css'

function Home() {
    const history = useHistory();
    const [{user,userData}, dispatch] = useStateValue();
    const [isWebView] = useState((history.location.pathname.indexOf("webview") > 0) ? true : false);
    let params = useParams();
    var location = useLocation();

    useEffect(()=>{
        if(location.hash){
            myfun(location.hash);
        }
        if(isWebView && params && "email" in params){

            auth.signOut()
            dispatch({
                type:"SET_USER",
                user:{}
            })
            dispatch({
                type:"SET_USER_DATA",
                userData:{}
            })
        }
    },[location])

    var myfun = function(ele){

        var element = document.querySelector(ele);
        if (element) {
            element.scrollIntoView();
        }
    }


    return (
        <>

        <div className="home">
            <Hero/>
            <Features/>
            <Performance/>
            <DownloadLinks/>
            <ContactUs/>
            <Footer/>
        </div>

        </>

  );
}

export default Home;
