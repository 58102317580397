import React, { useRef, useState } from 'react';
import { pushData, updateDocument, setRealtimeDoc, setFirestoreDocument } from "../../utility/helper";
import { useLoader } from "../../contexts/loaderContext";
import { auth, db, storage } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth"
import { useStateValue } from '../../contexts/StateProvider';
import { ModalComponent } from '../modal/modal';
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
// import { updateDocument, updateFirebaseDocument, updateFirestoreDocument } from "../../utility/helper";
// import mail from "../../images/mail.svg";
// import twitterIcon from "../../images/twitter.png";
// import facebookIcon from "../../images/facebook.svg";
// import twitchIcon from "../../images/twitch.png";
// import youtubeIcon from "../../images/youtube.png";
// import userIcon from "../../images/user.svg";
// import BackButton from "../../components/backButton";

export default function EventSCore(props) {

    const override = css`
        display: block;
        margin: 0 7%;
        margin-left: auto;
        margin-right: auto;`;
        
    let [color, setColor] = useState("#ffffff");

    const [user] = useAuthState(auth)
    const { setLoader } = useLoader();
    const hiddenFileInput = React.useRef(null);
    const [ModalShow, setModalShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [firstUserModalStatus, setFirstUserModalStatus] = useState(true);
    const [secondUserModalStatus, setSecondUserModalStatus] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [scheduleDatas, setScheduleData] = useState(props.scheduleDatas || {});
    const FirstUserName = scheduleDatas?.FirstUserName ? scheduleDatas.FirstUserName : '-';
    const SecondUserName = scheduleDatas?.SecondUserName ? scheduleDatas.SecondUserName : '-';
    const [FirstUserScore, setFirstUserScore] = useState('');
    const [SecondUserScore, setSecondUserScore] = useState('');
    // const scoreVerify = '/^[0-9]+$/';
    // const [file, setFile] = useState('');
    // const [{ userData }, dispatch] = useStateValue();
    // Programatically click the hidden file input element
    // when the Button component is clicked
    // const handleClick = () => {
    //     setFile('');
    //     hiddenFileInput.current.click();
    // };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    // const handleChange = (event) => {
    //     setFile(event.target.files[0])
    // };

    const onCancel = async () => {
        if(loading){
            return false;
        }
        
        props.onHide(true);
    }

    const firstModalClick = async () => {
        if (!FirstUserScore || FirstUserScore.trim() == "") {
            setError(`Please Enter Score for ${FirstUserName}`);
        }
        else {
            setError('');
            setFirstUserModalStatus(false);
            setSecondUserModalStatus(true);
        }
    }

    const addObjectToSchedule = async (data)=>{

        var eventId = data.eventId;
        var nextRoundObj = data.nextRoundObj;
        var activeTab = data.activeTab;
        var winnerUser = data.winnerUser;

        var scoreObj = {
            FirstProfileImageUrl: winnerUser.userProfileImageUrl,
            FirstUserId: winnerUser.objectId,
            FirstUserName: FirstUserName,
            FirstUserScore: "",
            SecondProfileImageUrl: "",
            SecondUserId: "",
            SecondUserName: "",
            SecondUserScore: "",
            Winner: ""
        };
            
        db.collection("Schedules").doc(eventId).collection(nextRoundObj[activeTab]).add(scoreObj).then(()=>{
            return true;
        }).catch((err)=>{
            console.log(err);
            return false;
        });
        
    }

    const updateObjectOfSchedule = async (data)=>{
        var eventId = data.eventId;
        var nextRoundObj = data.nextRoundObj;
        var roundLastEntry = data.roundLastEntry;
        var activeTab = data.activeTab;
        var winnerUser = data.winnerUser;

        var scoreObj = { 
            SecondProfileImageUrl: winnerUser.userProfileImageUrl, 
            SecondUserId: winnerUser.objectId, 
            SecondUserName: SecondUserName, 
            SecondUserScore: "" 
        };

        db.collection("Schedules").doc(eventId).collection(nextRoundObj[activeTab]).doc(roundLastEntry[nextRoundObj[activeTab]]).update(scoreObj).then(()=>{
            return true;
        }).catch((err)=>{
            console.log(err);
            return false;
        });
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(loading){
            return false;
        }
        setLoading(true);
        
        if (!SecondUserScore || SecondUserScore.trim() == "") {
            setError(`Please Enter Score for ${SecondUserName}`);
            setLoading(false);
        }
        else if (SecondUserScore == FirstUserScore) {
            setError(`First user score and Second user score both are same. Enter different score.`);
            setLoading(false);
        }
        else {
            setError('');

            var eventId = props.eventId;
            var docData = props.scheduleDatas;
            var activeTab = props.activeTab;
            var eventData = props.eventData;
            var remainingRoundWinner = props.roundWinner;
            var roundLastEntry = props.roundLastEntry;
            var nextRoundObj = { round_1: 'round_2', round_2: 'round_3', round_3: 'round_4', round_4: 'round_5', round_5: 'round_6' };
            
            var winner = "";
            if (FirstUserScore > SecondUserScore) {
                winner = docData.FirstUserId;
            }
            else {
                winner = docData.SecondUserId;
            }
            
            db.collection('User').where('objectId', "==", winner).onSnapshot(async(snapshot) => {
                
                var winnerUser = await snapshot.docs[0].data();
                if(Object.keys(winnerUser).length > 0){
        
                    // Use this code for insert score to database.
                    db.collection("Schedules").doc(eventId).collection(activeTab).doc(docData.docId).update({ FirstUserScore: parseInt(FirstUserScore), SecondUserScore: parseInt(SecondUserScore), Winner: winner }).then(async function () {
                        // if (eventData.ExhibitionMaximumParticipants == 4 && activeTab == "round_2") {
                        if (eventData.ExhibitionMaximumParticipants == 4) {
        
                            if (activeTab == "round_2" && remainingRoundWinner[activeTab] == 2) {
                                var userBalance = parseFloat(winnerUser.userBalance) + parseFloat(eventData.ExhibitionTotalPrizes);
                                setFirestoreDocument('User', {userBalance: userBalance}, winner)
                                setRealtimeDoc('Users', {userBalance: userBalance}, winner)
                            }
                            else if(remainingRoundWinner[activeTab] > 2){
                                if (roundLastEntry[nextRoundObj[activeTab]] == "") {
                                       
                                    await addObjectToSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                                else {
                                    
                                    await updateObjectOfSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                            }
                            
                        }
                        else if (eventData.ExhibitionMaximumParticipants == 8) {
                            if (activeTab == "round_3" && remainingRoundWinner[activeTab] == 2) {
                                var userBalance = parseFloat(winnerUser.userBalance) + parseFloat(eventData.ExhibitionTotalPrizes);
                                setFirestoreDocument('User', {userBalance: userBalance}, winner)
                                setRealtimeDoc('Users', {userBalance: userBalance}, winner)
                            }
                            else if(remainingRoundWinner[activeTab] > 2){
                                if (roundLastEntry[nextRoundObj[activeTab]] == "") {
                                       
                                    await addObjectToSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                                else {
                                    
                                    await updateObjectOfSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                            }
                        }
                        else if (eventData.ExhibitionMaximumParticipants == 16) {
                            if (activeTab == "round_4" && remainingRoundWinner[activeTab] == 2) {
                                var userBalance = parseFloat(winnerUser.userBalance) + parseFloat(eventData.ExhibitionTotalPrizes);
                                setFirestoreDocument('User', {userBalance: userBalance}, winner)
                                setRealtimeDoc('Users', {userBalance: userBalance}, winner)
                            }
                            else if(remainingRoundWinner[activeTab] > 2){
                                if (roundLastEntry[nextRoundObj[activeTab]] == "") {
                                       
                                    await addObjectToSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                                else {
                                    
                                    await updateObjectOfSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                            }
                        }
                        else if (eventData.ExhibitionMaximumParticipants == 32) {
                            if (activeTab == "round_5" && remainingRoundWinner[activeTab] == 2) {
                                var userBalance = parseFloat(winnerUser.userBalance) + parseFloat(eventData.ExhibitionTotalPrizes);
                                setFirestoreDocument('User', {userBalance: userBalance}, winner)
                                setRealtimeDoc('Users', {userBalance: userBalance}, winner)
                            }
                            else if(remainingRoundWinner[activeTab] > 2){
                                if (roundLastEntry[nextRoundObj[activeTab]] == "") {
                                       
                                    await addObjectToSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                                else {
                                    
                                    await updateObjectOfSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                            }
                        }
                        else if (eventData.ExhibitionMaximumParticipants == 64) {
                            if (activeTab == "round_6" && remainingRoundWinner[activeTab] == 2) {
                                var userBalance = parseFloat(winnerUser.userBalance) + parseFloat(eventData.ExhibitionTotalPrizes);
                                setFirestoreDocument('User', {userBalance: userBalance}, winner)
                                setRealtimeDoc('Users', {userBalance: userBalance}, winner)
                            }
                            else if(remainingRoundWinner[activeTab] > 2){
                                if (roundLastEntry[nextRoundObj[activeTab]] == "") {
                                       
                                    await addObjectToSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                                else {
                                    
                                    await updateObjectOfSchedule({eventId: eventId, nextRoundObj: nextRoundObj, roundLastEntry: roundLastEntry, activeTab: activeTab, winnerUser: winnerUser});
        
                                }
                            }
                        }
                        setSuccess("Score successfully entered");
                        setLoading(false);
                        setTimeout(() => {
                            // console.log('Hello, World!')
                            props.onHide(true);
                        }, 3000);
                    }).catch((err) => {
                        setError(err);
                        console.log(err);
                        setLoading(false);
                    });
                }
                else{
                    setError(`Winner-User not found.`);
                    setLoading(false);
                }
            });

        }
    }


    return (
        <>

            <form className="form px-4" onSubmit={handleSubmit}>
                {(firstUserModalStatus) &&
                    <>
                        <p className="text-danger text-left">{error}</p>
                        <p className="text-success text-center">{success}</p>
                        <div className={`header-content`}>
                            <p className='font-weight-700 text-left text-light' style={{ fontSize: '18px' }}>Enter score for {FirstUserName}</p>
                        </div>
                        <div className="w-100">
                            <input type='text' name='score' style={{ fontSize: '12pt', width: '100%', minHeight: '36pt', maxHeight: '50pt', textAlign: 'left', textIndent: '6px' }} placeholder="Enter Score" onChange={(e) => setFirstUserScore(e.target.value)} />
                        </div>
                        <div class="d-flex flex-row align-item-center justify-content-around">
                            <button type='button' style={{ background: '#ffffff', width: '45%', height: '45px' }} onClick={() => { onCancel() }}>
                                <span className='form-btn' style={{ fontSize: '16px' }}>Cancel</span>
                            </button>
                            <button type='button' style={{ width: '45%', height: '45px' }} onClick={() => { firstModalClick() }}>
                                <span className='form-btn' style={{ fontSize: '16px' }}>OK</span>
                            </button>
                        </div>
                    </>
                }
                {(secondUserModalStatus && SecondUserName.trim() != "") &&
                    <>
                        <p className="text-danger text-left">{error}</p>
                        <p className="text-success text-center">{success}</p>
                        <div className={`header-content`}>
                            <p className='font-weight-700 text-left text-light' style={{ fontSize: '18px' }}>Enter score for {SecondUserName}</p>
                        </div>
                        <div className="w-100">
                            <input type='text' name='score' style={{ fontSize: '12pt', width: '100%', minHeight: '36pt', maxHeight: '50pt', textAlign: 'left', textIndent: '6px' }} placeholder="Enter Score" onChange={(e) => setSecondUserScore(e.target.value)} />
                        </div>
                        <div class="d-flex flex-row align-item-center justify-content-around">
                            <button type='button' style={{ background: '#ffffff', width: '45%', height: '45px' }} onClick={() => { onCancel() }}>
                                <span className='form-btn' style={{ fontSize: '16px' }}>Cancel</span>
                            </button>
                            <button type='submit' style={{ width: '45%', height: '45px' }}>
                                <span className='form-btn' style={{ fontSize: '16px' }}>{(loading) ? <PuffLoader css={override} loading={props.loading} size={24} color={color} /> : 'OK'}</span>
                            </button>
                        </div>
                    </>
                }
            </form>
        </>
    );

}

